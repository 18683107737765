import React from 'react';
import TickSolid from "../../../assets/icons/TickSolid";
import TickOutline from "../../../assets/icons/TickOutline";
import ErrorSolid from "../../../assets/icons/ErrorSolid";

export interface ITabLinkIncidentPanel {
	className?: string;
	children: React.ReactNode;
	onClick?: () => any;
	active?: boolean;
	disabled?: boolean;
	errorExists?: boolean;
	type?: 'incidentPanel'
	readTabs?: {name: string, isRead: boolean}[];
}

const TabLinkIncidentPanel: React.FC<ITabLinkIncidentPanel> = ({
	children,
	active = false,
	onClick,
	className = '',
	disabled = false,
	errorExists = false,
	type,
	readTabs,
}) => {
	const tabStyles = () => {
		if (readTabs){
			if (readTabs.find((tab: {name: string, isRead: boolean }) => tab.name===children)?.isRead===true){
				return ''
			} else {
				return 'text-secondary-50 hover:text-secondary-200'
			}
		} else {
			return 'text-secondary-50 hover:text-secondary-200'
		}
	}
	const getIcon = () => {

		if (readTabs?.find((tab: {name: string, isRead: boolean }) => tab.name===children)?.isRead===true){
			if (errorExists){
				if (active){
					return <TickOutline color={'#FAA431'}/>
				} else {
					return <ErrorSolid color={'#DC3F4F'}/>
				}
			}
			if (active){
				return <TickOutline color={'#FAA431'}/>
			} else {
				return <TickSolid color={'#32BFA7'}/>
			}
		} else {
			return <TickOutline color={'#BCBCBC'}/>
		}
	}

	return (
		<>
			{disabled ? (
				<div
					className={`flex justify-center items-center p-2 pb-2 bg-transparent cursor-pointer text-gray-300 font-bold font-inter tracking-normal mb-[5px]
					${active ? 'text-primary-500 border-b-[4px] border-primary !mb-[1px]' : `${tabStyles()}`}
					${className}`}
				>
					{type === 'incidentPanel' && (
						<span className="mr-[5px]">
							{getIcon()}
						</span>
					)}
					{children}
				</div>
			) : (
				<div
					onClick={onClick}
					className={`flex justify-center items-center p-2 pb-2 bg-transparent cursor-pointer font-inter text-base font-bold tracking-normal mb-[5px]
					${active ? 'text-primary-500 border-b-[4px] border-primary !mb-[1px]' : `${tabStyles()}`}
					${className}`}
				>
					{type==='incidentPanel' && (
						<span className="mr-[5px]">
							{getIcon()}
						</span>
					)}
					{children}
				</div>
			)}
		</>
	);
};

export default TabLinkIncidentPanel;
