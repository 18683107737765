import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import Button from 'components/atoms/Button';
import Spinner from 'components/atoms/Spinner';
import TabLink from 'components/atoms/TabLink';
import IncidentSearchBar from 'components/molecules/IncidentSearchBar';
import InPageSearch from 'components/molecules/InPageSearch';
import SlideOver from 'components/molecules/SlideOver';
import CommentPanel from 'components/organisms/CommentPanel';
import IncidentPanel from 'components/organisms/IncidentPanel';
import Table from 'components/organisms/Table';
import MainLayoutV2 from 'components/templates/MainLayout/MainLayoutV2';
import { useEffect, useState } from 'react';
import { useGetPermissionsMutation } from 'redux/api/vehicleIncidents';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	useCreateIncidentActivityMutation,
	useCreateIncidentMutation,
	useDeleteIncidentActivityMutation,
	useGetIncidentActivityMutation,
	useGetIncidentMutation,
	useGetVehicleIncidentsMutation,
	useUpdateIncidentActivityMutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector, useQuery } from 'redux/hooks';
import {
	closeCommentModal,
	closeIncidentModal,
	openIncidentModal,
	updateIncidentActionState,
	updateBasicPermissions,
} from 'redux/slices/vehicleIncidentsSlice';
import { IQueryPeriod } from 'types/VehicleIncidents';
import { hasAccess } from 'utils/permissions';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import ExportIcon from '../assets/icons/ExportIcon';
import SideBarIcon from '../assets/icons/SideBarIcon';
import { IVehicleIncident } from 'types/VehicleIncidents';

const Incidents = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const [filters, setFiltersRaw] = useState<string>(
		'period=today&status=open,new'
	);
	const [open, setOpen] = useState(false);
	const [getIncidents, { data, isSuccess, isLoading: isListLoading }] =
		useGetVehicleIncidentsMutation();
	const [
		getIncidentsCloseModal,
		{ data: dataCloseModal, isSuccess: isSuccessCloseModal },
	] = useGetVehicleIncidentsMutation();
	const IncidentState = useAppSelector((state) => state.vehicleIncident);
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const userId = useAppSelector((state) => state.user.id);
	const [createIncidentActivity] = useCreateIncidentActivityMutation();
	const [deleteIncidentActivity] = useDeleteIncidentActivityMutation();
	const [updateIncidentActivity] = useUpdateIncidentActivityMutation();
	const [getPermissions, { data: permissionsData }] =
		useGetPermissionsMutation();
	const [createIncident, { isLoading }] = useCreateIncidentMutation();
	const [getIncident] = useGetIncidentMutation();
	const [getIncidentActivity, { data: activityData }] =
		useGetIncidentActivityMutation();
	const { uid, action } = useParams();
	const query = useQuery();
	const navigate = useNavigate();
	const [previousReportOpen, setPreviousReportOpen] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const incidentFilters = useAppSelector(
		(state) => state.vehicleIncident.filters
	);
	const [currentTableData, setCurrentTableData] = useState<IVehicleIncident[]>(
		[]
	);
	const [currentPage, setCurrentPage] = useState(1);
	const [loadMoreDisabled, setLoadMoreDisabled] = useState(true);
	// @ts-ignore
	const nuisanceFilter = incidentFilters?.status?.find((item) => {
		return item.title === 'nuisance';
	});
	// @ts-ignore
	const duplicateFilter = incidentFilters?.status?.find((item) => {
		return item.title === 'duplicate';
	});

	const includeDuplicateInCsv = () => {
		return duplicateFilter?.checked;
	};

	const includeNuisanceInCsv = () => {
		return nuisanceFilter?.checked;
	};

	const resetPagination = () => {
		setCurrentPage(1);
		setCurrentTableData([]);
		setLoadMoreDisabled(true);
	};

	const setFilters = (newFilters: string, page: number = 1) => {
		if (page === 1) {
			resetPagination();
		}

		if (newFilters) {
			newFilters += `&`;
		}
		newFilters += `page=${page}`;

		setCurrentPage(page);
		setFiltersRaw(newFilters);
	};

	useEffect(() => {
		if (uid) {
			if (action?.toLowerCase() === 'view') {
				dispatch(updateIncidentActionState('view'));
			}
			setFilters(`uid=${uid}`);
		}
		// eslint-disable-next-line
	}, [uid]);

	useEffect(() => {
		if (query && query.get('view') && !previousReportOpen) {
			dispatch(updateIncidentActionState('view'));
			setFilters(`id=${query.get('view')}`);
		}
		// eslint-disable-next-line
	}, [query]);

	useEffect(() => {
		if (permissionsData) {
			dispatch(updateBasicPermissions(permissionsData));
		}
		// eslint-disable-next-line
	}, [permissionsData]);

	useEffect(() => {
		getPermissions();
		// eslint-disable-next-line
	}, [getPermissions]);

	useEffect(() => {
		if (searchParams.get('branch_name') || searchParams.get('category')) {
			const filterObj = {};

			if (searchParams.get('branch_name')) {
				//@ts-ignore
				filterObj.branch = searchParams.get('branch_name');
			}

			if (searchParams.get('status')) {
				//@ts-ignore
				filterObj.status = searchParams.get('status');
			}

			if (searchParams.get('category')) {
				//@ts-ignore
				filterObj.incidentCategory = searchParams.get('category');
			}

			if (searchParams.get('dateFrom')) {
				//@ts-ignore
				filterObj.from = searchParams.get('dateFrom');
			}

			if (searchParams.get('dateTo')) {
				//@ts-ignore
				filterObj.to = searchParams.get('dateTo');
			}

			setFilters(new URLSearchParams(filterObj).toString());
			setPeriod('all');
		}
		getIncidents(filters);
		// eslint-disable-next-line
	}, [filters, period]);

	useEffect(() => {
		if (isSuccess && data) {
			if (
				IncidentState.action &&
				IncidentState.action === 'view' &&
				data.data.length
			) {
				const selectedIncident = data.data[0];
				const payload = {
					incidentReportId: selectedIncident.id,
					incidentBranchId: selectedIncident.branch.id,
					incidentSection1Id: selectedIncident?.incident_section_1?.id,
					incidentSection2Id: selectedIncident?.incident_section_2?.id,
					incidentSection3Id: selectedIncident?.incident_section_3?.id,
					incidentSection4Id: selectedIncident?.incident_section_4?.id,
					incidentSection5Id: selectedIncident?.incident_section_5?.id,
					incidentSection7Id: selectedIncident?.incident_section_7?.id,
				};
				dispatch(openIncidentModal(payload));
				createIncidentActivity({
					incident: selectedIncident.id,
					user: userId,
					type: 'view report',
					content: 'Report opened',
				});
				dispatch(updateIncidentActionState(null));
			} else {
				const newTableData = [...currentTableData];

				for (const incident of data?.data) {
					const idInCurrentData = newTableData.findIndex((currentIncident) => {
						return incident.id === currentIncident.id;
					});

					if (idInCurrentData > -1) {
						newTableData[idInCurrentData] = incident;
					} else {
						if (data?.prepend) {
							newTableData.unshift(incident);
						} else {
							newTableData.push(incident);
						}
					}
				}

				setCurrentTableData(newTableData);

				if (
					data?.pagination?.pageCount &&
					currentPage < data?.pagination?.pageCount
				) {
					setLoadMoreDisabled(false);
				} else {
					setLoadMoreDisabled(true);
				}
			}
		}
		// eslint-disable-next-line
	}, [data, isSuccess]);

	useEffect(() => {
		if (isSuccessCloseModal && dataCloseModal) {
			const newTableData = [...currentTableData];

			for (const incident of dataCloseModal?.data) {
				const idInCurrentData = newTableData.findIndex((currentIncident) => {
					return incident.id === currentIncident.id;
				});

				if (idInCurrentData > -1) {
					newTableData[idInCurrentData] = incident;
				} else {
					newTableData.unshift(incident);
				}
			}

			setCurrentTableData(newTableData);
			if (
				dataCloseModal?.pagination?.pageCount &&
				currentPage < dataCloseModal?.pagination?.pageCount
			) {
				setLoadMoreDisabled(false);
			} else {
				setLoadMoreDisabled(true);
			}
		}
		// eslint-disable-next-line
	}, [dataCloseModal, isSuccessCloseModal]);

	useEffect(() => {
		if (
			!IncidentState.incidentReportOpen &&
			previousReportOpen &&
			query.get('view')
		) {
			navigate('/incidents');
		} else if (
			IncidentState.incidentReportOpen &&
			!previousReportOpen &&
			!query.get('view') &&
			IncidentState.incidentReportId
		) {
			navigate(`/incidents?view=${IncidentState.incidentReportId}`);
		}
		setPreviousReportOpen(IncidentState.incidentReportOpen);
		// eslint-disable-next-line
	}, [IncidentState.incidentReportOpen]);

	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const handleCloseIncidentReport = () => {
		dispatch(closeIncidentModal());
		setFilters(filters);
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const createNewIncident = async () => {
		//find branch to attach to incident as a default
		const branchIdMap = userPermission?.map((permission) =>
			Number(permission.branch.id)
		);

		//create a new incident
		if (branchIdMap) {
			const newIncident = await createIncident({
				branch: branchIdMap[0],
			});

			const newIncidentId = 'data' in newIncident ? newIncident.data?.id : null;

			if (!newIncidentId) {
				return;
			}

			await getIncident(newIncidentId)
				.unwrap()
				.then((response) => {
					const {
						incident_section_1,
						incident_section_2,
						incident_section_3,
						incident_section_4,
						incident_section_5,
						incident_section_7,
					} = response?.incidentData;

					const updateIncidentStatePayload = {
						incidentReportId: newIncidentId,
						incidentBranchId: branchIdMap[0].toString(),
						incidentSection1Id: incident_section_1.id,
						incidentSection2Id: incident_section_2.id,
						incidentSection3Id: incident_section_3.id,
						incidentSection4Id: incident_section_4.id,
						incidentSection5Id: incident_section_5.id,
						incidentSection7Id: incident_section_7.id,
						isNewIncident: true,
					};
					dispatch(openIncidentModal(updateIncidentStatePayload));
				});
		}
	};

	const hasManagerAccess = () => {
		return hasAccess(userPermission, 'manager', 'incident_level', 'write');
	};

	let headings = [];

	if (open) {
		headings = [
			'Branch',
			'Date',
			'Severity',
			'Ticket No.',
			'Client',
			'Created By',
			'Status',
		];
	} else {
		headings = [
			'Branch',
			'Incident #',
			'Category',
			'Date',
			'Person Involved',
			'Severity',
			'Ticket No.',
			'Client',
			'Created By',
			'Status',
		];
	}

	if (isLoading) {
		return (
			<MainLayoutV2 url="incidents">
				<Spinner />
			</MainLayoutV2>
		);
	}

	const extractText: string | number | any = (
		children: string | number | any
	) => {
		if (typeof children === 'string' || typeof children === 'number') {
			return children;
		}

		if (Array.isArray(children)) {
			return children.map((child) => extractText(child)).join(' ');
		}

		//@ts-ignore
		if (React.isValidElement(children) && children.props.children) {
			//@ts-ignore
			return extractText(children.props.children);
		}

		return '';
	};

	const renderHeadings = () => {
		if (!currentTableData) return [];
		const headings = [
			{
				value: 'Branch',
				alignment: 'left',
			},
			{
				value: 'Incident #',
				alignment: 'left',
			},
			{
				value: 'Category',
				alignment: 'left',
			},
			{
				value: 'Date',
				alignment: 'left',
			},
			{
				value: 'Person Involved',
				alignment: 'left',
			},
			{
				value: 'Severity',
				alignment: 'left',
			},
			{
				value: 'Ticket No.',
				alignment: 'left',
			},
			{
				value: 'Client',
				alignment: 'left',
			},
			{
				value: 'Created By',
				alignment: 'left',
			},
			{
				value: 'Status',
				alignment: 'left',
			},
		];

		return headings;
	};

	const renderRows = () => {
		if (!currentTableData) return [];
		const rows = currentTableData
			.filter((row) => {
				if (!includeDuplicateInCsv() && row.special_status === 'duplicate')
					return false;

				if (!includeNuisanceInCsv() && row.special_status === 'nuisance')
					return false;

				return true;
			})
			.map((row) => {
				return {
					type: 'default',
					values: [
						{
							value: row.branch.name,
							alignment: 'left',
						},
						{
							value: row.id,
							alignment: 'left',
						},
						{
							value:
								row.incident_section_4?.incident_category &&
								row.incident_section_4?.incident_category.length
									? `${row.incident_section_4.incident_category[0].label}`
									: `No Category`,
							alignment: 'left',
						},
						{
							value: row.incident_section_1?.incident_date
								? new Date(row.incident_section_1?.incident_date)
										.toLocaleDateString('en-AU', {
											weekday: 'short',
											day: 'numeric',
											month: 'short',
										})
										.replace(',', '')
								: 'No date',
							alignment: 'left',
						},
						{
							value:
								row.incident_section_2?.personInvolved &&
								row.incident_section_2.personInvolved.length
									? `${row.incident_section_2.personInvolved[0].personInvolved_name}`
									: '',
							alignment: 'left',
						},
						{
							value: row.incident_section_4?.severity_rating,
							alignment: 'left',
						},
						{
							value: row.ticket_number,
							alignment: 'left',
						},
						{
							value: row.client,
							alignment: 'left',
						},
						{
							value: row.submitted_by,
							alignment: 'left',
						},
						{
							value: row.special_status ? row.special_status : row.status,
							alignment: 'left',
						},
					],
				};
			});
		return rows || [];
	};

	const countTotalIncidentsPerCategory = () => {
		const categoryCountMap: { [key: string]: number } = {};

		currentTableData.forEach(
			(incident: { incident_section_4: { incident_category: any[] } }) => {
				incident.incident_section_4?.incident_category?.forEach(
					(category: { label: string | number }) => {
						categoryCountMap[category.label] =
							(categoryCountMap[category.label] || 0) + 1;
					}
				);
			}
		);

		return categoryCountMap;
	};

	const countIncidentsByBranchAndCategory = () => {
		const branchCategoryCountMap: { [key: string]: { [key: string]: number } } =
			{};

		currentTableData.forEach(
			(incident: {
				branch: { name: any };
				incident_section_4: { incident_category: any[] };
			}) => {
				const branchName = incident.branch.name;
				incident.incident_section_4?.incident_category?.forEach((category) => {
					if (!branchCategoryCountMap[branchName]) {
						branchCategoryCountMap[branchName] = {};
					}
					branchCategoryCountMap[branchName][category.label] =
						(branchCategoryCountMap[branchName][category.label] || 0) + 1;
				});
			}
		);

		return branchCategoryCountMap;
	};

	const totalIncidentsPerCategory = countTotalIncidentsPerCategory();
	const incidentsByBranchAndCategory = countIncidentsByBranchAndCategory();

	const exportToExcel = () => {
		const workbook = XLSX.utils.book_new();

		const sheetData = [['Category Report'], ['Category', 'Total Incidents']];
		for (const category in totalIncidentsPerCategory) {
			if (totalIncidentsPerCategory.hasOwnProperty(category)) {
				const totalAsString = String(totalIncidentsPerCategory[category]);
				sheetData.push([category, totalAsString]);
			}
		}
		const sheet = XLSX.utils.aoa_to_sheet(sheetData);

		XLSX.utils.book_append_sheet(workbook, sheet, 'Category Report');

		for (const branch in incidentsByBranchAndCategory) {
			if (incidentsByBranchAndCategory.hasOwnProperty(branch)) {
				const branchData = incidentsByBranchAndCategory[branch];
				const sheetData = [
					[`${branch} Incidents`],
					['Category', 'Branch Total', 'Total', 'Percentage'],
				];

				for (const category in branchData) {
					if (branchData.hasOwnProperty(category)) {
						const incidents = branchData[category];
						const categoryTotal = totalIncidentsPerCategory[category] || 0;
						const percentage =
							((incidents / categoryTotal) * 100).toFixed(2) + '%';
						sheetData.push([
							category,
							String(incidents),
							String(categoryTotal),
							percentage,
						]);
					}
				}

				const sheet = XLSX.utils.aoa_to_sheet(sheetData);
				XLSX.utils.book_append_sheet(workbook, sheet, branch);
			}
		}

		XLSX.writeFile(workbook, 'report.xlsx');
	};

	const clickLoadMore = () => {
		setFilters(filters.replace(/&page=\d+/, ''), currentPage + 1);
	};

	const showLoadMore = () => {
		return currentTableData.length > 0 && !loadMoreDisabled;
	};

	const getIncidentsWrapped = (filters: string, prepend: boolean = false) => {
		const tempFilters = prepend ? filters + '&prepend=true' : filters;
		getIncidentsCloseModal(tempFilters);
	};

	return (
		<MainLayoutV2
			url="incidents"
			query={query.get('view') ? `?view=${query.get('view')}` : undefined}
		>
			<main className="flex h-full">
				<InPageSearch open={open} setOpen={setOpen} searchType={'incident'}>
					<IncidentSearchBar setFilters={setFilters} setPeriod={setPeriod} />
				</InPageSearch>
				<div className="flex-auto overflow-y-scroll">
					<div className="flex items-start justify-between pt-7 px-4 sticky top-0 z-30 bg-white border-t-[1px] 2xl:border-b-secondary-100 border-solid 2xl:border-[1px]">
						<div className="flex flex-col justify-start">
							<Button
								type="secondary"
								onClick={handleOpen}
								className={`${
									open ? 'invisible' : ''
								} text-[14px] tracking-[1.92px] !rounded-3xl`}
							>
								Search By
								<span className="ml-[4px]">
									<SideBarIcon color="#FAA431" />
								</span>
							</Button>
						</div>
						<div className="hidden 2xl:block">
							<div className="flex items-center justify-center flex-1 w-full space-x-4">
								<TabLink
									onClick={() => {
										setFilters('period=today&status=new');
										setPeriod('today');
									}}
									active={period === 'today'}
									v2={true}
								>
									Process area
								</TabLink>
								<TabLink
									onClick={() => {
										setFilters('period=week&status=open');
										setPeriod('week');
									}}
									active={period === 'week'}
									v2={true}
								>
									Open reports
								</TabLink>
								<TabLink
									onClick={() => {
										setFilters(
											`period=month&status=open&assignedUserId=${userId}`
										);
										setPeriod('month');
									}}
									active={period === 'month'}
									v2={true}
								>
									Open actions assigned to me
								</TabLink>
								<TabLink
									onClick={() => {
										setFilters('period=all');
										setPeriod('all');
									}}
									active={period === 'all'}
									v2={true}
								>
									All
								</TabLink>
							</div>
						</div>
						<div className="flex flex-row">
							<Button
								onClick={exportToExcel}
								className="mr-4 tracking-[1.92px] !rounded-3xl"
								type="quaternary"
							>
								<span className="mr-[4px]">
									<ExportIcon />
								</span>
								Export XLSX
							</Button>
							<CSVLink
								data={[...renderRows()].map((row) =>
									row.values.map((value) => extractText(value.value))
								)}
								headers={[
									...renderHeadings().map((heading) =>
										extractText(heading.value)
									),
								]}
								filename="report"
								className="px-3 py-2 text-sm flex justify-center items-center relative rounded-[5px] bg-transparent text-primary border border-primary
									hover:bg-primary-10 hover:border-primary-500 hover:text-primary-500
									active:border-transparent active:text-primary-600 active:border-transparent font-inter text-[16px] font-bold leading-[24px] uppercase
									disabled:border-transparent disabled:text-secondary-50
									mr-4 tracking-[1.92px] !rounded-3xl
									h-10
								"
							>
								<span className="mr-[4px]">
									<ExportIcon />
								</span>
								Export CSV
							</CSVLink>
							{hasManagerAccess() && (
								<Button
									onClick={createNewIncident}
									className="text-[14px] tracking-[1.92px] !rounded-3xl"
								>
									<PlusIcon className="h-5 w-5 mr-1" />
									New Report
								</Button>
							)}
						</div>
					</div>
					<div className="2xl:hidden mt-[20px] border-solid border-b-[1px] border-secondary-200">
						<div className="flex items-center justify-center flex-1 w-full space-x-4">
							<TabLink
								onClick={() => {
									setFilters('period=today&status=new,open');
									setPeriod('today');
								}}
								active={period === 'today'}
								v2={true}
							>
								Process area
							</TabLink>
							<TabLink
								onClick={() => {
									setFilters('period=week&status=new,open');
									setPeriod('week');
								}}
								active={period === 'week'}
								v2={true}
							>
								Open reports
							</TabLink>
							<TabLink
								onClick={() => {
									setFilters(
										`period=month&status=new,open&assignedUserId=${userId}`
									);
									setPeriod('month');
								}}
								active={period === 'month'}
								v2={true}
							>
								Open actions assigned to me
							</TabLink>
							<TabLink
								onClick={() => {
									setFilters('period=all');
									setPeriod('all');
								}}
								active={period === 'all'}
								v2={true}
							>
								All
							</TabLink>
						</div>
					</div>
					<Table
						headings={headings}
						data={currentTableData}
						refetchData={() => getIncidents(filters)}
						splitAssignedToMe={false}
						hideColumns={open}
					/>
					<div className="flex justify-center mt-4 mb-4">
						{showLoadMore() && (
							<Button
								onClick={clickLoadMore}
								isDisabled={loadMoreDisabled || isListLoading}
							>
								Load more
							</Button>
						)}
					</div>
				</div>
			</main>
			<SlideOver
				open={IncidentState.commentsOpen}
				onClose={handleCloseComments}
			>
				<CommentPanel
					id={IncidentState.commentIncidentId}
					onClose={handleCloseComments}
					createActivity={(payload) => createIncidentActivity(payload)}
					updateActivity={updateIncidentActivity}
					deleteActivity={deleteIncidentActivity}
					activityData={activityData}
					getActivity={getIncidentActivity}
					activityType="incident"
					hasPermissionAccess={hasManagerAccess}
				/>
			</SlideOver>
			<SlideOver
				open={IncidentState.incidentReportOpen}
				onClose={handleCloseIncidentReport}
			>
				<IncidentPanel
					getIncidents={getIncidentsWrapped}
					filters={filters}
					onClose={handleCloseIncidentReport}
				/>
			</SlideOver>
		</MainLayoutV2>
	);
};

export default Incidents;
