import Header from 'components/molecules/Header';
import SideBar from 'components/molecules/SideBar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigationTotalQuery } from 'redux/api/navigationAPI';
import { useAppDispatch } from 'redux/hooks';
import { logoutUser } from 'redux/slices/userSlice';
import { INavigation } from 'types/Layout';
import { ToastContainer } from 'react-toastify';
import { ILayout } from './MainLayout';

const defaultLinks: INavigation[] = [
	{
		name: 'Incidents',
		href: 'incidents',
		current: false,
		total: 0,
	},
	{
		name: 'Faults',
		href: 'vehicle-faults',
		current: false,
		total: 0,
	},
	{
		name: 'Surveys',
		href: 'surveys',
		current: false,
		total: 0,
	},
];

const MainLayoutV2: React.FC<ILayout> = ({
	url,
	query,
	children,
	highlight,
	surveyDropdown = false,
	setFilters,
}) => {
	const [links, setLinks] = useState(defaultLinks);

	const { data, isSuccess } = useNavigationTotalQuery();

	const [selectedLink, setSelectedLink] = useState(
		defaultLinks.filter((link) => link.href === url)[0]
	);

	const [isSidebarHidden, setIsSidebarHidden] = useState(true);

	const handleToggleSidebar = () => {
		setIsSidebarHidden(!isSidebarHidden);
	};

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isSuccess && data) {
			const linksObj = links.reduce(
				(
					acc: {
						incidentLink: INavigation | null;
						vehicleFaultLink: INavigation | null;
						surveyLink: INavigation | null;
					},
					link
				) => {
					if (link.href === 'incidents') {
						acc.incidentLink = link;
					} else if (link.href === 'vehicle-faults') {
						acc.vehicleFaultLink = link;
					} else if (link.href === 'surveys') {
						acc.surveyLink = link;
					}
					return acc;
				},
				{ incidentLink: null, vehicleFaultLink: null, surveyLink: null }
			);

			if (linksObj.incidentLink) {
				linksObj.incidentLink.total = data.incidents;
			}
			if (linksObj.vehicleFaultLink) {
				linksObj.vehicleFaultLink.total = data.vehicleFaults;
			}
			if (linksObj.surveyLink) {
				linksObj.surveyLink.total = data.surveys;
			}

			setLinks([...links]);
		}
		// eslint-disable-next-line
	}, [data, isSuccess]);

	useEffect(
		() => {
			if (links) {
				const newLinks = links.map((link) => {
					link.current = link.href === selectedLink?.href;

					if (highlight) {
						link.current = link.name.toLowerCase() === highlight.toLowerCase();
					}
					return link;
				});

				setLinks(newLinks);
				navigate(`/${selectedLink?.href ?? url}${query || ''}`);
			}
		},
		// eslint-disable-next-line
		[selectedLink, setLinks]
	);

	const onLogout = () => {
		dispatch(logoutUser());
		navigate('/login');
	};

	return (
		<div className="flex flex-row flex-1 w-screen h-screen">
			<SideBar
				navigation={links}
				setSelected={setSelectedLink}
				isSidebarHidden={isSidebarHidden}
			/>
			<div className="flex flex-col flex-1">
				<Header
					data={selectedLink}
					onLogout={onLogout}
					surveyDropdown={surveyDropdown}
					setFilters={setFilters}
					isSidebarHidden={isSidebarHidden}
					onToggleSidebar={handleToggleSidebar}
				/>
				<div className="relative overflow-hidden h-full">{children}</div>
			</div>
			<ToastContainer position="top-right" autoClose={5000} />
		</div>
	);
};

export default MainLayoutV2;
