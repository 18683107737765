import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import { IOption } from 'types/VehicleIncidents';
import CheckBoxV2 from '../../atoms/CheckBoxV2';
import React, { useState } from 'react';

export interface IFormCheckBoxGroupV2 {
	checkBoxName:
		| 'injuryType'
		| 'bodyParts'
		| 'bodyPartsInjured'
		| 'incidentMechanism'
		| 'incidentAgency'
		| 'incidentCategory'
		| 'lifeSavingRules'
		| 'codeOfConduct'
		| 'hierachyControl'
		| 'emergencyResponseAttendance'
		| 'mviCausation';
	checkBoxGroupData: IOption[];
	onHandleChange: (value: string, checkBoxGroup: IOption[]) => void;
	descriptionClassName?: string;
	descriptionDirection?: 'flex-row' | 'flex-col';
	errorState?: boolean;
	gridColumns?: '0' | '4' | '5' | '6' | '7' | '8';
	className?: string;
	otherTextBox?: boolean;
	otherTextBoxValue?: string;
	otherTextBoxOnChange?: (value: string) => void;
	otherTextBoxClassName?: string;
	isDisabled?: boolean;
	handleOnBlur?: () => void;
	singleSelect?: boolean;
	otherTextBoxPlaceholder?: string;
}

const FormCheckBoxGroupV2: React.FC<IFormCheckBoxGroupV2> = ({
	checkBoxName,
	checkBoxGroupData,
	onHandleChange,
	descriptionClassName,
	descriptionDirection,
	errorState = false,
	gridColumns = '0',
	className = '',
	otherTextBox = false,
	otherTextBoxValue = '',
	otherTextBoxOnChange,
	otherTextBoxClassName = 'w-[400px] lg:-ml-24 rounded-lg',
	isDisabled = false,
	singleSelect = false,
	handleOnBlur,
	otherTextBoxPlaceholder,
}) => {
	const [textBoxInput, setTextBoxInput] = useState<string>(otherTextBoxValue);
	const errorStyle = errorState ? 'p-3 border border-red-500 rounded-lg' : '';
	const gridStyle = {
		0: '',
		4: `grid gap-4 grid-col grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 space-y-2 ml-1`,
		5: 'grid gap-4 grid-col grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 space-y-2 ml-1',
		6: 'grid gap-2 grid-col grid-cols-3 2xl:grid-cols-6 space-y-2',
		7: 'grid gap-4 grid-col grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 space-y-2 ml-1',
		8: 'grid gap-4 grid-col grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 space-y-2 ml-1',
	};

	const onChange = (id: number, checked: boolean) => {
		if (checkBoxGroupData) {
			const newCheckBoxState = singleSelect
				? ([
						...checkBoxGroupData.map((item) => {
							return { ...item, checked: false };
						}),
				  ] as IOption[])
				: ([...checkBoxGroupData] as IOption[]);

			const index = newCheckBoxState.findIndex(
				(item) => item.id === Number(id)
			);
			newCheckBoxState[index] = {
				...newCheckBoxState[index],
				checked: !checked,
			};
			onHandleChange(checkBoxName, newCheckBoxState);
		}
	};

	return (
		<>
			<div
				className={`${className} ${gridStyle[gridColumns]} ${errorStyle}`}
				style={errorState ? { padding: '2rem' } : { padding: '0' }}
			>
				{checkBoxGroupData !== undefined &&
					(checkBoxGroupData as IOption[]).map((item: IOption) => {
						return (
							<CheckBoxV2
								key={item.id}
								item={item}
								onChange={onChange}
								descriptionClassName={descriptionClassName}
								descriptionDirection={descriptionDirection}
								isDisabled={isDisabled}
							/>
						);
					})}
				{otherTextBox && otherTextBoxOnChange && (
					<Input
						type="text"
						value={otherTextBoxValue}
						inputClassName={`!h-[60px]`}
						onChange={(input: string) => setTextBoxInput(input)}
						size="normal"
						rounded="medium"
						isDisabled={isDisabled}
						onBlur={() => {
							otherTextBoxOnChange(textBoxInput);
							if (handleOnBlur) {
								handleOnBlur();
							}
						}}
						placeholder={otherTextBoxPlaceholder}
					/>
				)}
			</div>
			{errorState && (
				<Text className="text-red-500">
					Please tick at least one checkbox *
				</Text>
			)}
		</>
	);
};

export default FormCheckBoxGroupV2;
