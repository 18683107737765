import { Combobox, Transition } from '@headlessui/react';
import React, { useState, Fragment, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

export interface IComboBox {
	label: string;
	options: { id: string; label: string }[];
	selected?: string;
	isDisabled?: boolean;
	onChange?: (value: string) => void;
	required?: boolean;
	className?: string;
}

const ComboBox: React.FC<IComboBox> = ({
	label,
	options,
	selected,
	isDisabled = false,
	onChange,
	required = false,
	className = '',
}) => {
	useEffect(() => {
		let firstSelected = options.find((option) => option.label === selected);

		if (selected && firstSelected) {
			setSelectedOption(firstSelected);
		} else if (!selected) {
			setSelectedOption(null);
		}
	}, [selected, options]);

	const firstSelected = options.find((option) => option.label === selected);
	const [selectedOption, setSelectedOption] = useState(
		firstSelected ? firstSelected : null
	);
	const [query, setQuery] = useState('');

	const filteredOptions =
		query === ''
			? options
			: options.filter((option) => {
					return option.label.toLowerCase().includes(query.toLowerCase());
			  });

	const onChangeHandler = (value: { id: string; label: string }) => {
		setSelectedOption(value);
		onChange?.(value.label);
	};

	return (
		<div className={className ? className : 'mb-10'}>
			<Combobox
				value={selectedOption}
				onChange={onChangeHandler}
				disabled={isDisabled}
			>
				<div className="relative">
					<div className="block w-full border-gray-300 focus:ring-gray-300 focus:border-gray-300 focus:border-primary">
						<Combobox.Label className="block font-inter text-base font-bold tracking-normal text-secondary-900 mb-[12px]">
							{label} {required && <span className="text-red">*</span>}
						</Combobox.Label>
						<Combobox.Input
							onChange={(event) => setQuery(event.target.value)}
							className="py-3 block w-full border-gray-300 focus:ring-gray-300 focus:border-gray-300 focus:border-primary pr-10 h-12 rounded-sm mt-1"
							displayValue={(option: { id: string; label: string }) =>
								option?.label ?? query
							}
						/>
						<Combobox.Button className="absolute bottom-0 py-3.5 right-0 flex items-center px-3">
							<ChevronDownIcon
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</Combobox.Button>
					</div>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						afterLeave={() => setQuery('')}
					>
						<Combobox.Options
							className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-40`}
						>
							{filteredOptions.length === 0 && query !== '' ? (
								<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
									Nothing found.
								</div>
							) : (
								filteredOptions.map((option) => (
									<Combobox.Option
										key={option.id}
										value={option}
										className={({ active }) =>
											`relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-primary-100 ${
												active ? 'bg-primary-100' : 'text-gray-900'
											}`
										}
									>
										{option.label}
									</Combobox.Option>
								))
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>
		</div>
	);
};

export default ComboBox;
