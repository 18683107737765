import Heading from 'components/atoms/Heading';
import SlideDown from 'components/atoms/SlideDown';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection3Mutation,
	useUpdateIncidentSection3Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IOption,
	ISection3ErrorStates,
	IVehicleIncidentSection3,
	IVehicleIncidentSection3Payload,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { isCheckBoxChecked } from 'utils/isCheckBoxChecked';
import FormCheckBoxGroupV2 from '../../molecules/FormCheckboxGroupV2';
import Divider from '../../atoms/Divider';
import LoaderSpinner from '../../atoms/LoaderSpinner';

export interface IIncidentSection3 {
	sectionId: number | null;
	incidentSection3: IVehicleIncidentSection3;
	setIncidentSection3: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection3>
	>;
	setSection3IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection3ErrorStates>
	>;
	setSection: (section: IQueryReportSection) => void;
	handleValidate: () => void;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
}

export type ISection3Type =
	| 'bodyPartsInjuredOtherInput'
	| 'injuryTypeOtherInput'
	| 'incidentMechanismOtherInput'
	| 'incidentAgencyOtherInput'
	| 'injuryType'
	| 'bodyParts'
	| 'bodyPartsInjured'
	| 'incidentMechanism'
	| 'incidentAgency'
	| 'mviCausation';

const IncidentSection3: React.FC<IIncidentSection3> = ({
	sectionId,
	incidentSection3,
	setIncidentSection3,
	setSection3IncidentErrorStates,
	setSection,
	handleValidate,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
}) => {
	const [initialSetSection, setInitialSetSection] = useState<boolean>(false);
	const [changedItem, setChangedItem] = useState<string>('');
	const [section3, setSection3] =
		useState<IVehicleIncidentSection3>(incidentSection3);
	const [isInjury, setIsInjury] = useState<boolean>(false);
	const [isRestrictedInjury, setIsRestrictedInjury] = useState(false);
	const [getIncidentSection3, { data, isSuccess, isLoading }] =
		useGetIncidentSection3Mutation();
	const [updateIncidentSection3, { isLoading: saving }] =
		useUpdateIncidentSection3Mutation();
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection3(sectionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			data &&
			initialSetSection &&
			!(changedItem === 'injuryTypeOtherInput')
		) {
			handleOnBlur();
		} else if (data && !(changedItem === 'injuryTypeOtherInput')) {
			setInitialSetSection(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [section3, isInjury, isRestrictedInjury]);

	useEffect(() => {
		setIncidentSection3((prevState) => {
			return {
				...prevState,
				bodyParts: section3.bodyParts,
				bodyPartsInjured: section3.bodyPartsInjured,
				injuryType: section3.injuryType,
				incidentMechanism: section3.incidentMechanism,
				incidentAgency: section3.incidentAgency,
				mviCausation: section3.mviCausation,
				injuries: isInjury,
				restricted_injury: isRestrictedInjury,
			};
		});
	}, [
		setIncidentSection3,
		section3.bodyParts,
		section3.bodyPartsInjured,
		section3.injuryType,
		section3.incidentMechanism,
		section3.incidentAgency,
		section3.mviCausation,
		isInjury,
		isRestrictedInjury,
	]);

	useEffect(() => {
		setIsInjury(section3.injuries);
		setIsRestrictedInjury(section3.restricted_injury);
	}, [section3.injuries, section3.restricted_injury]);

	useEffect(() => {
		if (isSuccess && data) {
			const {
				injuries,
				restricted_injury,
				body_injured,
				injury_type,
				incident_mechanism,
				incident_agency,
				injury_type_other_description,
				incident_mechanism_other_description,
				incident_agency_other_description,
				body_injured_other_description,
			} = data.data;

			const bodyPartInjuredArray: IOption[] = [];
			const bodyPartInjuredArray2 = [...body_injured];
			const arrayToFilter = [
				'Left',
				'Right',
				'Upper',
				'Lower',
				'Psych',
				'Internal Organs',
			];

			for (let i = body_injured.length - 1; i >= 0; i--) {
				if (arrayToFilter.includes(body_injured[i].title)) {
					bodyPartInjuredArray.push(body_injured[i]);
					bodyPartInjuredArray2.splice(i, 1);
				}
			}
			bodyPartInjuredArray.sort((a, b) => a.title.localeCompare(b.title));
			bodyPartInjuredArray2.sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);

			const injuryTypeArray: IOption[] = [...injury_type].sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);
			const incidentMechanismArray: IOption[] = [...incident_mechanism].sort(
				(a, b) =>
					a.title === 'Other'
						? 1
						: b.title === 'Other'
						? -1
						: a.title.localeCompare(b.title)
			);
			const incidentAgencyArray: IOption[] = [...incident_agency].sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);

			setSection3({
				...section3,
				bodyParts: bodyPartInjuredArray,
				bodyPartsInjured: bodyPartInjuredArray2,
				bodyPartsInjuredOtherInput: body_injured_other_description,
				injuryType: injuryTypeArray,
				injuryTypeOtherInput: injury_type_other_description,
				incidentMechanism: incidentMechanismArray,
				incidentMechanismOtherInput: incident_mechanism_other_description,
				incidentAgency: incidentAgencyArray,
				incidentAgencyOtherInput: incident_agency_other_description,
				injuries,
				restricted_injury,
			});
			setIsInjury(injuries);
			setIsRestrictedInjury(restricted_injury);
		}
		//removes dependency for "section 3"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection3Update = (
		property: string,
		value: string | IOption[]
	) => {
		const newSection3 = section3;
		setChangedItem(property);
		if (
			property === 'bodyPartsInjuredOtherInput' ||
			property === 'incidentAgencyOtherInput' ||
			property === 'injuryTypeOtherInput' ||
			property === 'incidentMechanismOtherInput'
		) {
			if (typeof value === 'string') {
				newSection3[property] = value;
				setIncidentSection3(newSection3);
			}
		} else if (
			property === 'bodyPartsInjured' ||
			property === 'bodyParts' ||
			property === 'incidentAgency' ||
			property === 'incidentMechanism' ||
			property === 'injuryType' ||
			property === 'mviCausation'
		) {
			setSection3({
				...section3,
				[property]: value,
			});
		}
	};

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}

		const bodyPartsCheckBoxGroup = section3.bodyParts as IOption[];
		const bodyPartsInjuredCheckBoxGroup =
			section3.bodyPartsInjured as IOption[];
		const bodyInjuredCheckboxGroup = [
			...bodyPartsCheckBoxGroup,
			...bodyPartsInjuredCheckBoxGroup,
		];
		const injuryTypeCheckBoxGroup = section3.injuryType as IOption[];
		const incidentMechanismCheckBoxGroup =
			section3.incidentMechanism as IOption[];
		const incidentAgencyCheckBoxGroup = section3.incidentAgency as IOption[];

		const payload: IVehicleIncidentSection3Payload = {
			id: sectionId,
			injuries: isInjury,
			restricted_injury: isRestrictedInjury,
			body_injured: bodyInjuredCheckboxGroup,
			injury_type: injuryTypeCheckBoxGroup,
			incident_mechanism: incidentMechanismCheckBoxGroup,
			incident_agency: incidentAgencyCheckBoxGroup,
			injury_type_other_description: section3.injuryTypeOtherInput,
			incident_mechanism_other_description:
				section3.incidentMechanismOtherInput,
			incident_agency_other_description: section3.incidentAgencyOtherInput,
			body_injured_other_description: section3.bodyPartsInjuredOtherInput,
		};

		await updateIncidentSection3(payload);

		setSection3IncidentErrorStates({
			section_3_body_parts: isCheckBoxChecked(bodyInjuredCheckboxGroup),
			section_3_injury_type: isCheckBoxChecked(injuryTypeCheckBoxGroup),
			section_3_incident_mechanism: isCheckBoxChecked(
				incidentMechanismCheckBoxGroup
			),
			section_3_incident_agency: isCheckBoxChecked(incidentAgencyCheckBoxGroup),
		});
	};

	return (
		<>
			{isLoading || !data ? (
				<LoaderSpinner />
			) : (
				<>
					<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">
						Injury / Incident Details
					</Heading>

					<ToggleButtonV2
						toggle={isInjury}
						setToggle={setIsInjury}
						label="Were there any injuries?"
						isDisabled={disableIncidentWriteAccess}
					/>

					<SlideDown
						open={isInjury}
						className={`${isInjury ? 'my-2 !h-auto' : 'hidden'}`}
					>
						<ToggleButtonV2
							toggle={isRestrictedInjury}
							setToggle={setIsRestrictedInjury}
							label="Is this a restricted injury preventing the employee from performing full duties?"
							isDisabled={disableIncidentWriteAccess}
							margins="mb-8"
						/>

						<Heading
							className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5 uppercase mb-[32px]"
							type="h2"
						>
							Part of Body Injured *
						</Heading>

						<FormCheckBoxGroupV2
							checkBoxName="bodyParts"
							checkBoxGroupData={section3.bodyParts}
							onHandleChange={handleSection3Update}
							gridColumns={'4'}
							handleOnBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<FormCheckBoxGroupV2
							checkBoxName="bodyPartsInjured"
							checkBoxGroupData={section3.bodyPartsInjured}
							onHandleChange={handleSection3Update}
							gridColumns={'4'}
							otherTextBox={true}
							otherTextBoxValue={section3.bodyPartsInjuredOtherInput}
							otherTextBoxOnChange={(value: string) =>
								handleSection3Update('bodyPartsInjuredOtherInput', value)
							}
							handleOnBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>

						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<Heading
							className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5 uppercase mb-[32px]"
							type="h2"
						>
							Injury Type *
						</Heading>
						<FormCheckBoxGroupV2
							checkBoxName="injuryType"
							checkBoxGroupData={section3.injuryType}
							onHandleChange={handleSection3Update}
							gridColumns={'4'}
							otherTextBox={true}
							otherTextBoxValue={section3.injuryTypeOtherInput}
							otherTextBoxOnChange={(value: string) =>
								handleSection3Update('injuryTypeOtherInput', value)
							}
							handleOnBlur={handleOnBlur}
							otherTextBoxClassName="w-[400px] lg:-ml-24"
							isDisabled={disableIncidentWriteAccess}
						/>
						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<Heading
							className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5 uppercase mb-[32px]"
							type="h2"
						>
							Incident Mechanism *
						</Heading>
						<FormCheckBoxGroupV2
							checkBoxName="incidentMechanism"
							checkBoxGroupData={section3.incidentMechanism}
							onHandleChange={handleSection3Update}
							gridColumns={'4'}
							otherTextBox={true}
							otherTextBoxValue={section3.incidentMechanismOtherInput}
							otherTextBoxOnChange={(value: string) =>
								handleSection3Update('incidentMechanismOtherInput', value)
							}
							handleOnBlur={handleOnBlur}
							otherTextBoxClassName="w-[400px] xl:-ml-44"
							isDisabled={disableIncidentWriteAccess}
						/>

						<Divider
							className="my-[64px]"
							color="!border-t-[4px] border-orange-500"
						/>
						<Heading
							className="font-inter leading-[26px] tracking-[-1px] text-secondary pb-5 uppercase mb-[32px]"
							type="h2"
						>
							Incident Agency *
						</Heading>
						<FormCheckBoxGroupV2
							checkBoxName="incidentAgency"
							checkBoxGroupData={section3.incidentAgency}
							onHandleChange={handleSection3Update}
							gridColumns={'4'}
							otherTextBox={true}
							otherTextBoxValue={section3.incidentAgencyOtherInput}
							otherTextBoxOnChange={(value: string) =>
								handleSection3Update('incidentAgencyOtherInput', value)
							}
							handleOnBlur={handleOnBlur}
							otherTextBoxClassName="w-[400px] lg:-ml-44 rounded-lg"
							isDisabled={disableIncidentWriteAccess}
						/>
					</SlideDown>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<div className="flex justify-between mb-[64px]">
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[2].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section2');
							}}
							type="primary"
							isDisabled={savingIncident}
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
						>
							Back to Person Involved
						</Button>
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[2].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section4');
							}}
							type="primary"
							isDisabled={savingIncident}
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
						>
							Continue to Analysis
						</Button>
					</div>
				</>
			)}
			;
		</>
	);
};

export default IncidentSection3;
