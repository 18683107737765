import React from 'react';
import { Dialog } from '@headlessui/react';
import { IncidentPdfTypes } from 'types/VehicleIncidents';
import Divider from 'components/atoms/Divider';
import Button from 'components/atoms/Button';
import { XIcon } from '@heroicons/react/outline';

export interface IIncidentPdfDownloadDialog {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onChange: (type: IncidentPdfTypes) => void;
}

const IncidentPdfDownloadDialog: React.FC<IIncidentPdfDownloadDialog> = ({
	isOpen,
	setIsOpen,
	onChange,
}) => {
	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			as="div"
			className="relative z-[100000] focus:outline-none"
		>
			<div className="fixed inset-0 z-[100000] w-screen overflow-y-auto bg-black bg-opacity-50">
				<div className="flex justify-center p-4 mt-[35vh]">
					<Dialog.Panel className="w-full max-w-xl rounded-xl bg-primary-50 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
						<div className="flex flex-row justify-between content-center items-center">
							<Dialog.Title
								as="h3"
								className="text-base/7 font-medium text-secondary align-middle"
							>
								PDF Report Download
							</Dialog.Title>
							<Button
								type={'quaternary'}
								className="!rounded-3xl !py-0 !px-2 tracking-[1.92px] h-9 border-0"
								onClick={() => {
									setIsOpen(false);
								}}
							>
								<XIcon height={18} width={18} />
							</Button>
						</div>
						<Divider className="mt-2 mb-3 border-primary" />
						<Dialog.Description>
							Would you like to download the full report, or the redacted report
							(with no personally identifiable information)?
						</Dialog.Description>
						<div className="mt-4 flex justify-center space-x-2">
							<Button onClick={() => onChange('full')} className="w-1/2">
								Full Report
							</Button>
							<Button onClick={() => onChange('redacted')} className="w-1/2">
								Redacted Report
							</Button>
						</div>
					</Dialog.Panel>
				</div>
			</div>
		</Dialog>
	);
};

export default IncidentPdfDownloadDialog;
