import { useEffect } from 'react';
import {
	ISection1ErrorStates,
	ISection2ErrorStates,
	ISection3ErrorStates,
	ISection4ErrorStates, ISection5ErrorStates,
	ITabState,
} from 'types/VehicleIncidents';

export const useSetErrorState = (
	sectionErrorState:
		| ISection1ErrorStates
		| ISection2ErrorStates
		| ISection3ErrorStates
		| ISection4ErrorStates
		| ISection5ErrorStates,
	section: 'section1' | 'section2' | 'section3' | 'section4' | 'section5',
	setErrorTabStates: React.Dispatch<React.SetStateAction<ITabState>>
) => {
	useEffect(() => {
		if (Object.values(sectionErrorState).every((item) => item)) {
			setErrorTabStates((prevState) => {
				return {
					...prevState,
					[section]: false,
				};
			});
		}
	}, [section, sectionErrorState, setErrorTabStates]);
};
