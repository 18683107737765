import Input from 'components/atoms/Input';
import DateInput from 'components/atoms/DateInput';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { ISection2Type } from 'types/VehicleIncidents';
import Button from 'components/atoms/Button';
import Heading from '../../atoms/Heading';

export interface IPersonInvolvedCard {
	handleSection2Update: (
		property: ISection2Type,
		sectionLabel: string | Date,
		index?: number,
		disableUpdateRequest?: boolean
	) => void;
	deletePersonInvolved(id: number): void;
	id: number;
	personInvolved: IPersonInvolved;
	index: number;
	isDisabled: boolean;
	onBlur?: () => void;
}

export interface IPersonInvolved {
	id: number;
	personInvolved_name: string;
	personInvolved_phone_number: string;
	personInvolved_start_date: Date | undefined;
	personInvolved_task: string;
}

const PersonInvolved: React.FC<IPersonInvolvedCard> = ({
	handleSection2Update,
	deletePersonInvolved,
	personInvolved,
	id,
	index,
	isDisabled = false,
	onBlur,
}) => {
	const [payload, setPayload] = useState(personInvolved);

	useEffect(() => {
		setPayload(personInvolved);
	}, [personInvolved]);

	const handleOnChange = (
		property: ISection2Type,
		value: string | Date,
		disableUpdateRequest?: boolean
	) => {
		handleSection2Update(property, value, index, disableUpdateRequest);
	};

	return (
		<div className="py-[24px] px-[40px] border-solid border-[1px] bg-[#FEEDD6] mb-4 rounded-md">
			<div className="flex flex-row justify-between">
				<div className="flex flex-col justify-center">
					<Heading type="h2">Details: Person {index + 1}</Heading>
				</div>
				<div className="flex flex-col justify-center mt-6">
					<Button
						type="quinary"
						className="font-bold !rounded-3xl px-[24px] py-[10px] tracking-[1.92px] uppercase"
						onClick={() => deletePersonInvolved(id)}
						size="md"
					>
						<TrashIcon height={20} width={18} className="cursor-pointer" />
						&nbsp;Delete
					</Button>
				</div>
			</div>
			<div className="flex gap-x-8 w-full flex-wrap my-3">
				<Input
					type="text"
					onChange={(value: string) =>
						handleOnChange('personInvolved_name', value, true)
					}
					value={payload.personInvolved_name}
					onBlur={onBlur}
					className="basis-1/5 grow"
					label="Name"
					required={true}
					isDisabled={isDisabled}
				/>
				<Input
					type="tel"
					onChange={(value: string) =>
						handleOnChange('personInvolved_phone_number', value, true)
					}
					onBlur={onBlur}
					value={payload.personInvolved_phone_number}
					className="basis-1/5 grow"
					label="Phone Number"
					required={true}
					isDisabled={isDisabled}
				/>
				<Input
					type="text"
					onChange={(value: string) =>
						handleOnChange('personInvolved_task', value, true)
					}
					value={payload.personInvolved_task}
					onBlur={onBlur}
					className="basis-1/5 grow"
					label="Task"
					isDisabled={isDisabled}
				/>
				<div className="basis-1/5 grow">
					<DateInput
						placeholder="Select Date"
						onChange={(value: Date) =>
							handleOnChange('personInvolved_start_date', value, true)
						}
						onBlur={onBlur}
						className="w-full"
						label="Start Date"
						required={true}
						iconClassName="text-black"
						selected={payload.personInvolved_start_date}
						wrapperClassName="react-datepicker-margin-0"
						isDisabled={isDisabled}
					/>
				</div>
			</div>
		</div>
	);
};

export default PersonInvolved;
