import React from 'react';
import {TailSpin} from "react-loader-spinner";

export interface ILoaderSpinner {}

const LoaderSpinner: React.FC<ILoaderSpinner> = () => {

    return (
        <>
            <div className="h-[95vh] flex flex-col justify-center">
                <div className="flex flex-row justify-center font-inter text-base font-bold tracking-normal text-secondary-900">
                    Fetching data...
                </div>
                <div className="flex flex-row justify-center">
                    <TailSpin
                        height="25"
                        width="25"
                        ariaLabel="tail-spin-loading"
                        color="#FE9F00"
                        wrapperClass="flex mt-2"
                        radius={2}
                        visible
                    />
                </div>
            </div>
        </>
    )
};

export default LoaderSpinner;
