import { useEffect } from 'react';
import {
	IIncident,
	ISection5ErrorStates,
	IVehicleIncidentSection5,
} from 'types/VehicleIncidents';

export const useGetIncidentSection5 = (
	isSuccess: boolean,
	data: IIncident | undefined,
	setIncidentSection5: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection5>
	>,
	setSection5IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection5ErrorStates>
	>
) => {
	useEffect(() => {
		if (isSuccess && data) {
			if (isSuccess && data) {
				const {
					id,
					incidentReportId,
					section1Id,
					shift_start_time,
					incident_investigation_required,
					percentage_of_shift_worked,
					investigator,
					users,
					attachments,
				} = data.incidentData.incident_section_5 ?? {
					shift_start_time: '',
					incident_investigation_required: false,
					percentage_of_shift_worked: '',
					investigator: '',
					users: [
						{
							id: '1',
							label: '',
						},
					],
					attachments: [],
				};

				setIncidentSection5({
					id: id ? id : 0,
					section1Id: section1Id,
					incidentReportId: incidentReportId,
					shift_start_time: shift_start_time,
					incident_investigation_required: incident_investigation_required,
					percentage_of_shift_worked: percentage_of_shift_worked,
					investigator: investigator
						? `${investigator.first_name} ${investigator.last_name}`
						: '',
					users: users,
					attachments: attachments,
				});

				const validateInvestigator = () => {
					if (incident_investigation_required) {
						if (investigator) {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				};

				const validateShiftStartTime = () => {
					if (shift_start_time) {
						return true;
					} else {
						return false;
					}
				};

				setSection5IncidentErrorStates({
					section_5_investigator: validateInvestigator(),
					section_5_shift_start_time: validateShiftStartTime(),
				});
			}
		}
	}, [isSuccess, data, setIncidentSection5, setSection5IncidentErrorStates]);
};
